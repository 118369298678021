@media screen and (max-width: 1365px) {
  .descriptionParagraph {
    p {
      width: calc(100vw - 90px);
      font-size: 35px;
      line-height: 35px;
      margin: 0;
    }
  }
}
@media screen and (min-width: 1366px) {
  .descriptionParagraph {
    width: 1200px;
    p {
      width: calc(100vw - 90px);
      font-size: 35px;
      line-height: 35px;
      margin: 0;
    }
    .descriptionCards {
      display: flex;
      width: 1200px;
      align-items: center;
      justify-content: space-between;
    }
  }
}

@media screen and (max-width: 1365px) and (min-width: 768px) {
  .descriptionParagraph {
    width: 650px;
    display: flex;
    flex-direction: column;
    p {
      width: calc(100vw - 90px);
      font-size: 35px;
      line-height: 35px;
      margin: 0;
    }
    .descriptionCards {
      display: flex;
      width: 650px;
      align-items: center;
      justify-content: space-between;
      flex-direction: column;
    }
  }
}
