.productImage {
  margin: 10px calc((100vw - 306px) / 2);
  width: 306px;
  height: 306px;
  border-radius: 5px;
  overflow: hidden;
  background-position: center center;
  background-size: cover;
  box-shadow: 0 0 10px #295cd420;
}

@media screen and (max-width: 1365px) and (min-width: 768px) {
  .productImage {
    margin: 10px calc((100vw - 650px) / 2);
    width: 650px;
    height: 320px;
    border-radius: 5px;
    overflow: hidden;
    background-position: center center;
    background-size: cover;
    box-shadow: 0 0 10px #295cd420;
  }
}
