.loading {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 1001;
  .logo {
    width: 85px;
  }
  div {
    text-align: center;
    margin: 55px 0;
    h1 {
      font-size: 23px;
      color: #e30613;
      margin: 0;
    }
    p {
      font-size: 18px;
      color: #262626;
      margin: 0;
      margin-top: 11px;
    }
  }
}
