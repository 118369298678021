@font-face {
  font-family: "Kollektif"; /*Can be any text*/
  src: local("Kollektif"), url("./fonts/Kollektif.ttf") format("truetype");
}
@font-face {
  font-family: "KollektifBold"; /*Can be any text*/
  src: local("KollektifBold"),
    url("./fonts/KollektifBold.ttf") format("truetype");
}
@font-face {
  font-family: "FuturaMedium"; /*Can be any text*/
  src: local("FuturaMedium"), url("./fonts/FuturaMedium.ttf") format("truetype");
}

body {
  margin: 0;
  font-family: "Kollektif";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.page {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.separatorBig {
  width: 100%;
  height: 60px;
}

.separatorSmall {
  width: 100%;
  height: 18px;
}

p {
  color: #262626;
}
