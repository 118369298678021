@media screen and (max-width: 1365px) {
  .productDescription {
    width: 306px;
    display: flex;
    flex-direction: column;
    .productDescriptionParagraph {
      display: flex;
      justify-content: left;
      align-items: center;
      flex-direction: column;
      .productDescriptionParagraphTitle {
        font-size: 20px;
        margin-bottom: 17px;
        width: 100%;
      }
      .productDescriptionParagraphText {
        font-size: 18px;
        color: #66737c;
      }
      .productDescriptionParagraphBulletContainer {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-top: 17px;
        .productDescriptionParagraphBullet {
          font-size: 16px;
          color: #e30613;
          width: 100%;
          margin: 5px 0;
        }
      }

      .productDescriptionParagraphImage {
        width: 306px;
        height: 195px;
        box-shadow: 0 0 10px #295cd420;
        border-radius: 5px;
        margin-top: 20px;
        background-position: center center;
        background-size: cover;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: 100%;
        }
      }
    }
  }
}
@media screen and (min-width: 1366px) {
  .productDescription {
    width: 1200px;
    display: flex;
    flex-direction: column;
    .productDescriptionParagraph {
      display: flex;
      justify-content: left;
      align-items: center;
      flex-direction: column;
      .productDescriptionParagraphTitle {
        font-size: 25px;
        margin-bottom: 0;
        width: 1150px;
      }
      .productDescriptionParagraphText {
        width: 1150px;
        font-size: 20px;
        color: #66737c;
        margin: 20px;
        margin-bottom: 0;
      }
      .productDescriptionParagraphBulletContainer {
        display: flex;
        flex-direction: column;
        width: 1150px;
        margin-bottom: 10px;
        .productDescriptionParagraphBullet {
          font-size: 18px;
          color: #e30613;
          width: 100%;
          margin: 5px 0;
        }
      }

      .productDescriptionParagraphImage {
        width: 1200px;
        height: 500px;
        box-shadow: 0 0 10px #295cd420;
        border-radius: 5px;
        margin-top: 10px;
        background-position: center center;
        background-size: cover;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: 100%;
        }
      }
    }
  }
}
