@media screen and (max-width: 1365px) {
  .descriptionCard {
    width: 306px;
    height: 325px;
    border-radius: 5px;
    border: 2px solid #efdedf;
    overflow: hidden;
    margin-top: 20px;
    .descriptionCardImage {
      width: 100%;
      height: 212px;
      overflow: hidden;
      background-color: #efdedf;
      img {
        height: 100%;
      }
    }
    .descriptionCardText {
      height: 94px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      p {
        width: calc(100% - 13px * 2);
        height: calc(94px - 13px * 2);
        font-size: 20px;
        line-height: 22px;
      }
    }
  }
}
@media screen and (min-width: 1366px) {
  .descriptionCard {
    width: 370px;
    height: 306px;
    border-radius: 5px;
    border: 2px solid #efdedf;
    overflow: hidden;
    margin-top: 20px;
    .descriptionCardImage {
      width: 100%;
      height: 212px;
      overflow: hidden;
      background-color: #efdedf;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 100%;
      }
    }
    .descriptionCardText {
      height: 100px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      p {
        width: calc(100% - 13px * 2);
        height: calc(94px - 13px * 2);
        font-size: 20px;
        line-height: 22px;
      }
    }
  }
}

@media screen and (max-width: 1365px) and (min-width: 768px) {
  .descriptionCard {
    width: 646px;
    height: 96px;
    border-radius: 5px;
    border: 2px solid #efdedf;
    overflow: hidden;
    margin-top: 20px;
    display: flex;
    .descriptionCardImage {
      width: 160px;
      height: auto;
      overflow: hidden;
      background-color: #efdedf;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 100%;
      }
    }
    .descriptionCardText {
      height: 100%;
      width: calc(100% - 160px);
      margin: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      p {
        margin: 0;
        font-size: 19px;
        //line-height: 22px;
      }
    }
  }
}
