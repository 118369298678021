.toastBackground {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  background-color: #26262620;
  backdrop-filter: blur(2px);
  .toast {
    position: fixed;
    top: 110px;
    left: 0;
    right: 0;
    margin: 0 auto;
    background-color: white;
    box-shadow: 0 0 10px #295cd420;
    width: 306px;
    height: 80px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    p {
    }
  }
}
