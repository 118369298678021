@media screen and (max-width: 1365px) {
  .purchase {
    a {
      text-decoration: none;
      .backLink {
        display: flex;
        flex-direction: row;
        justify-content: left;
        align-items: center;
        width: 285px;
        svg {
          color: #e30613;
          margin-bottom: 2px;
        }
        p {
          font-size: 20px;
          margin: 0 2.5px;
          font-family: "Kollektif";
        }
      }
    }
    .formCart {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 306px;
      .cartItem {
        width: 306px;
        height: 80px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 5px;
        overflow: hidden;
        box-shadow: 0 0 10px #295cd420;
        margin: 7px 0;
        .cartItemImage {
          height: 80px;
          width: 80px;
          border-right: 2px solid #e30613;
          background-size: cover;
          background-position: center center;
        }
        .cartItemFirstLine {
          width: calc(100% - 80px);
          height: 100%;
          display: grid;
          grid-template-columns: 50% 50%;
          grid-template-rows: 25px auto;
          grid-row-gap: 5px;
          align-items: center;
          .itemTitle {
            width: 100%;
            grid-column-start: 1;
            grid-column-end: -1;
            p {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              margin-bottom: 0;
              margin-left: 10px;
            }
          }
          .itemOldPrice {
            width: 100px;
            display: flex;
            justify-content: right;
            p {
              text-decoration: line-through 2px;
              color: #ff0011;
              span{
                color: grey;
              }
            }
          }
          .itemDiscountedPrice {
            width: 100px;
            display: flex;
            justify-content: left;
            p {
              color: #e30613;
            }
          }
          .itemPrice {
            width: 100px;
            display: flex;
            justify-content: right;
            p {
              margin: 0 10px;
              color: #e30613;
            }

            .price {
              font-family: "FuturaMedium";
            }
          }
        }
        .itemDescription {
          display: none;
        }
      }
    }
    form {
      .formInputs {
        width: 306px;
        height: 243px;
        border-radius: 5px;
        overflow: hidden;
        input {
          width: calc(100% - 13px * 2);
          height: 60px;
          border: none;
          border-bottom: 1px solid #ededed;
          padding: 0 13px;
          font-family: "Kollektif";
          font-size: 18px;
        }
      }
      .purchaseButton {
        width: 306px;
        height: 45px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: none;
        border-radius: 4px;
        background-color: #e30613;
        p {
          margin-left: 13px;
          font-family: "Kollektif";
          font-size: 20px;
          color: white;
        }
        svg {
          margin-right: 13px;
          color: white;
        }
      }
      
    }
    .gdprParagraph {
      width: 285px;
      margin: 0;
      text-align: center;
      color: #66737c;
      font-size: 18px;
      line-height: 25px;
    }
  }
}
.coupon{
  display: grid;
  grid-template-columns: 785 px;
  p{
    width: auto;
    justify-content: end;
    font-family: "Kollektif";
    font-size: 18px;
    color: #e30613;
    margin-left: auto;
    margin-right: 0px;
  }
}
.formCoupon{
  display: grid;
  grid-template-columns: 250px 50px;
  grid-template-rows: 61px;
  grid-gap: 0px;
  width: 306px;
  min-height: 65px;
  height: auto;
  border-radius: 5px;
  overflow: hidden;
  div{
    input {
      width: calc(100% - 30px * 2);
      height: 60px;
      border: 1px solid #ededed;
      padding: 0 13px;
      font-family: "Kollektif";
      font-size: 18px;
    }
  } 
}
.couponButton {
  width: 61px;
  height: 61px;
  display: flex;
  justify-self: right;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 4px;
  background-color: #e30613;
  p {
    font-family: "Kollektif";
    font-size: 20px;
    color: white;
  }
  svg {
    color: white;
  }
}
@media screen and (min-width: 1366px) {
  .purchase {
    width: 1200px;
    .backLinkContainer {
      width: 1200px;
      display: flex;
      justify-content: left;
      align-items: center;
      a {
        text-decoration: none;
        .backLink {
          display: flex;
          flex-direction: row;
          justify-content: left;
          align-items: center;
          width: 285px;
          svg {
            color: #e30613;
            margin-bottom: 2px;
          }
          p {
            font-size: 20px;
            margin: 0 2.5px;
            font-family: "Kollektif";
          }
        }
      }
    }
    .formGrid {
      width: 1200px;
      display: grid;
      grid-template-columns: 370px 785px;
      grid-template-rows: 235px 59px;
      row-gap: 10px;
      column-gap: 45px;
      form {
        grid-row-start: 1;
        grid-row-end: -1;
        .formInputs {
          width: 370px;
          height: 243px;
          border-radius: 5px;
          overflow: hidden;
          input {
            width: calc(100% - 13px * 2);
            height: 59px;
            border: none;
            border-bottom: 1px solid #ededed;
            padding: 0 13px;
            font-family: "Kollektif";
            font-size: 18px;
          }
        }
        .purchaseButton {
          width: 370px;
          height: 59px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          border: none;
          border-radius: 4px;
          background-color: #e30613;
          cursor: pointer;
          p {
            margin-left: 13px;
            font-family: "Kollektif";
            font-size: 20px;
            color: white;
          }
          svg {
            margin-right: 13px;
            color: white;
          }
        }
      }
      .formCart {
        width: 785px;
        height: 240px;
        .cartItem {
          width: 100%;
          height: 120px;
          overflow: hidden;
          box-shadow: 0 0 10px #295cd420;
          border-radius: 5px;
          display: grid;
          column-gap: 0;
          row-gap: 0;
          grid-template-columns: [start] 120px [firstline] calc(100% - 120px) [end];
          grid-template-rows: [start] 60px [firstline] calc(100% - 30px) [end];
          .cartItemImage {
            grid-column-start: start;
            grid-column-end: firstline;
            grid-row-start: start;
            grid-row-end: end;
            height: 100%;
            width: 120px;
            background-position: center;
            background-size: cover;
            border-right: 2px solid #e30613;
          }
          .cartItemFirstLine {
            grid-column-start: firstline;
            grid-column-end: end;
            grid-row-start: start;
            grid-row-end: firstline;
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 0;
            .itemTitle {
              width: 565px;
              display: flex;
              justify-content: left;
              align-items: center;
              p {
                margin-left: 20px;
                font-size: 23px;
              }
            }
            .itemPrice {
              width: 100px;
              display: flex;
              justify-content: right;
              p {
                margin-right: 20px;
                font-size: 25px;
                color: #e30613;
              }

              .price {
                font-family: "FuturaMedium";
              }
            }
            .itemOldPrice {
              width: 100px;
              display: flex;
              justify-content: right;
              p {
                margin-right: 20px;
                font-size: 25px;
                text-decoration: line-through 3px;
                color: #ff0011;
                span{
                  
                  color: grey;
                }
              }

              .price {
                font-family: "FuturaMedium";
              }
            }
            .itemDiscountedPrice {
              width: 100px;
              display: flex;
              justify-content: right;
              p {
                margin-right: 20px;
                font-size: 25px;
                color: #e30613;
              }

              .price {
                font-family: "FuturaMedium";
              }
            }
          }
          .itemDescription {
            grid-column-start: firstline;
            grid-column-end: end;
            grid-row-start: firstline;
            grid-row-end: end;
            display: flex;
            justify-content: center;
            align-items: center;

            p {
              margin: 20px;
              color: #66737c;
              opacity: 0.5;
              font-size: 18px;
              margin-top: -30px;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              overflow: hidden;
            }
          }
        }
      }
      .coupon{
        display: grid;
        grid-template-columns: 785px;
        p{
          width: auto;
          justify-self: end;
          font-family: "Kollektif";
          font-size: 18px;
          color: #e30613;
          margin-left: auto;
          margin-right: 0px;
        }
        .formCoupon{
          display: grid;
          grid-template-columns: auto auto;
          justify-self: end;
          grid-column-gap: 20px;
          div{
            grid-row-start: 1;
            grid-row-end: 1;
          }
          input {
            grid-row-start: 1;
            grid-row-end: 1;
            width: calc(100% - 13px * 2);
            height: 60px;
            border: 1px solid #ededed;
            padding: 0 13px;
            font-family: "Kollektif";
            font-size: 18px;
          }
        }
        .couponButton {
          grid-row-start: 1;
          grid-row-end: 1;
          width: 60px;
          height: 61px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          border: none;
          border-radius: 4px;
          background-color: #e30613;
          cursor: pointer;
          p {
            margin-left: 13px;
            font-family: "Kollektif";
            font-size: 20px;
            color: white;
          }
          svg {
            margin-left: 11px;
            color: white;
          }
        }
      }
    }
    .gdprParagraph {
      color: #262626;
      font-size: 18px;
      margin: 5px;
      width: 360px;
      text-align: center;
    }
  }
}

@media screen and (max-width: 1365px) and (min-width: 768px) {
  .purchase {
    a {
      text-decoration: none;
      .backLink {
        display: flex;
        flex-direction: row;
        justify-content: left;
        align-items: center;
        width: 629px;
        svg {
          color: #e30613;
          margin-bottom: 2px;
        }
        p {
          font-size: 20px;
          margin: 0 2.5px;
          font-family: "Kollektif";
        }
      }
    }
    .formCart {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 650px;
      font-size: 20px;
      .cartItem {
        width: 650px;
        height: 80px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 5px;
        overflow: hidden;
        box-shadow: 0 0 10px #295cd420;
        margin: 7px 0;
        .cartItemImage {
          height: 80px;
          width: 80px;
          border-right: 2px solid #e30613;
          background-size: cover;
          background-position: center center;
        }
        .cartItemFirstLine {
          width: calc(100%);
          height: 100%;
          display: grid;
          grid-template-columns: repeat(8, 12.50%) ;
          grid-column-gap: 10px;
          grid-template-rows: 25px 55px;
          grid-row-gap: 5px;
          align-items: center;
          .itemTitle {
            width: 100%;
            grid-column-start: 1;
            grid-column-end: -1;
            p {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              margin-bottom: 0;
              margin-left: 10px;
            }
          }
          .itemOldPrice {
            width: 100%;
            display: flex;
            justify-content: right;
            p {
              text-decoration: line-through 2px;
              color: #ff0011;
              span{
                color: grey;
              }
            }
          }
          .itemDiscountedPrice {
            width: 100%;
            display: flex;
            justify-content: left;
            p {
              color: #e30613;
            }
          }
          .itemPrice {
            width: 100%;
            display: flex;
            justify-content: right;
            p {
              margin: 0;
              color: #e30613;
            }
          }
        }
        .itemDescription {
          display: none;
        }
      }
    }
    form {
      .formInputs {
        width: 650px;
        height: 243px;
        border-radius: 5px;
        overflow: hidden;
        input {
          width: calc(100% - 13px * 2);
          height: 60px;
          border: none;
          border-bottom: 1px solid #ededed;
          padding: 0 13px;
          font-family: "Kollektif";
          font-size: 18px;
        }
      }
      .purchaseButton {
        width: 650px;
        height: 45px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: none;
        border-radius: 4px;
        background-color: #e30613;
        p {
          margin-left: 13px;
          font-family: "Kollektif";
          font-size: 20px;
          color: white;
        }
        svg {
          margin-right: 13px;
          color: white;
        }
      }
    }
    .coupon{
      display: grid;
      grid-template-columns: 785 px;
      margin-top: 20px;
      p{
        width: auto;
        justify-content: end;
        font-family: "Kollektif";
        font-size: 18px;
        color: #e30613;
        margin-left: auto;
        margin-right: 0px;
      }
    }
    .formCoupon{
      display: grid;
      grid-template-columns: 250px 50px;
      grid-template-rows: 61px;
      grid-gap: 0px;
      width: 306px;
      min-height: 65px;
      height: auto;
      border-radius: 5px;
      overflow: hidden;
      justify-self: right;
      div{
        input {
          width: calc(100% - 30px * 2);
          height: 60px;
          border: 1px solid #ededed;
          padding: 0 13px;
          font-family: "Kollektif";
          font-size: 18px;
        }
      } 
    }
    .couponButton {
      width: 61px;
      height: 61px;
      display: flex;
      justify-self: right;
      justify-content: center;
      align-items: center;
      border: none;
      border-radius: 4px;
      background-color: #e30613;
      p {
        font-family: "Kollektif";
        font-size: 20px;
        color: white;
      }
      svg {
        color: white;
      }
    }
    .gdprParagraph {
      width: 629px;
      margin: 0;
      text-align: center;
      color: #66737c;
      font-size: 18px;
      line-height: 25px;
    }
  }
}
