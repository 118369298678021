@media screen and (max-width: 1365px) {
  .paragraph {
    width: 285px;
    margin: 0 10.5px;
    p {
      margin: 0;
      width: 100%;
    }
    .paragraphTitle {
      font-size: 35px;
      line-height: 35px;
    }
    .paragraphDescription {
      margin-top: 5px;
      font-size: 18px;
      line-height: 25px;
      color: #66737c;
    }
  }
}
@media screen and (min-width: 1366px) {
  .paragraph {
    width: 285px;
    margin: 0 10.5px;
    p {
      margin: 0;
      width: 100%;
    }
    .paragraphTitle {
      font-size: 35px;
      line-height: 35px;
    }
    .paragraphDescription {
      margin-top: 5px;
      font-size: 18px;
      line-height: 25px;
      color: #66737c;
    }
  }
}

@media screen and (max-width: 1365px) and (min-width: 768px) {
  .paragraph {
    width: calc(650px - 10.5 * 2px);
    margin: 0 10.5px;
    p {
      margin: 0;
      width: 100%;
    }
    .paragraphTitle {
      font-size: 35px;
      line-height: 35px;
    }
    .paragraphDescription {
      margin-top: 5px;
      font-size: 18px;
      line-height: 25px;
      color: #66737c;
    }
  }
}
