@media screen and (max-width: 1365px) {
  .product {
    a {
      text-decoration: none;
      .backLink {
        display: flex;
        flex-direction: row;
        justify-content: left;
        align-items: center;
        width: 285px;
        svg {
          color: #e30613;
          margin-bottom: 2px;
        }
        p {
          font-size: 20px;
          margin: 0 2.5px;
          font-family: "Kollektif";
        }
        .productName {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
    .productVideo {
      overflow: hidden;
      border-radius: 5px;
      width: 306px;
      height: 170px;
      box-shadow: 0 0 10px #295cd420;
    }
    .productDetails {
      width: 285px;
      height: 184px;
      margin: 13px;
      display: flex;
      flex-direction: column;
      align-items: center;
      div {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 60px;
        width: 285px;
        svg {
          margin-right: 13px;
        }
        p {
          margin: 0;
          margin-right: 5px;
          font-size: 20px;
        }
      }
      .productDetailsSeparator {
        width: 306px;
        height: 1px;
        background-color: #ededed;
      }
      .price {
        font-family: "FuturaMedium";
      }
    }
    .productDescriptionContainer {
      width: 285px;
      display: flex;
      flex-direction: column;
      align-items: center;
      div {
        width: 285px;
        display: flex;
        justify-content: left;
        align-items: center;
        svg {
          margin-right: 13px;
        }
        p {
          font-size: 20px;
          margin: 0;
        }
      }
      .productDetailsSeparator {
        margin-top: 20px;
        width: 306px;
        height: 1px;
        background-color: #ededed;
        margin: 20px 0;
      }
    }
    .purchaseButton {
      width: 306px;
      height: 45px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border: none;
      border-radius: 4px;
      background-color: #e30613;
      p {
        margin-left: 13px;
        font-family: "Kollektif";
        font-size: 20px;
        color: white;
      }
      svg {
        margin-right: 13px;
        color: white;
      }
    }
  }
}
@media screen and (min-width: 1366px) {
  .product {
    .productContainer {
      width: 1200px;
      a {
        text-decoration: none;
        .backLink {
          display: flex;
          flex-direction: row;
          justify-content: left;
          align-items: center;
          width: 375px;
          svg {
            color: #e30613;
            margin-bottom: 2px;
          }
          p {
            font-size: 20px;
            margin: 0 2.5px;
            font-family: "Kollektif";
          }
          .productName {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
      .productGrid {
        display: grid;
        column-gap: 30px;
        row-gap: 30px;
        grid-template-columns: [first] 509px [line2] 286px [line3] 335px [end];
        grid-template-rows: [first] 197px [line1] 59px [end];
        .productVideo {
          width: 509px;
          height: 286px;
          overflow: hidden;
          border-radius: 5px;
          box-shadow: 0 0 10px #295cd420;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .productSecPic {
          width: 286px;
          height: 286px;
          overflow: hidden;
          border-radius: 5px;
          box-shadow: 0 0 10px #295cd420;
          background-position: center center;
          background-size: cover;
          grid-column-start: line2;
          grid-column-end: line3;
          grid-row-start: first;
          grid-row-end: end;
          display: flex;
          justify-content: left;
          align-items: flex-start;
          p {
            font-size: 18px;
            padding: 10px;
            color: white;
            background-color: #262626;
            width: 120px;
            text-align: center;
            margin: 10px;
            border-radius: 5px;
            cursor: pointer;
          }
        }
        .morePictures {
          cursor: pointer;
          grid-column-start: line2;
          width: 251px;
          height: 59px;
          background-color: #262626;
          color: white;
          font-family: "Kollektif";
          border-radius: 5px;
          border: none;
          box-shadow: 0 0 10px #295cd420;
          display: flex;
          justify-content: space-between;
          align-items: center;
          display: none;
          Z p {
            color: white;
            margin-left: 13px;
            font-size: 20px;
          }
          svg {
            color: white;
            margin-right: 13px;
          }
        }
        .purchaseButton {
          cursor: pointer;
          grid-column-start: line3;
          grid-column-end: end;
          grid-row-start: line1;
          grid-row-end: end;
          width: 335px;
          height: 59px;
          background-color: #e30613;
          font-family: "Kollektif";
          border-radius: 5px;
          border: none;
          box-shadow: 0 0 10px #295cd420;
          display: flex;
          justify-content: space-between;
          align-items: center;
          p {
            color: white;
            margin-left: 13px;
            font-size: 20px;
          }
          svg {
            color: white;
            margin-right: 13px;
          }
        }
        .productDetails {
          grid-column-start: line3;
          grid-column-end: end;
          grid-row-start: first;
          grid-row-end: line1;
          width: 335px;
          height: 185px;
          margin-top: 12px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          div {
            display: flex;
            flex-direction: row;
            align-items: center;
            height: 60px;
            width: 335px;
            svg {
              margin-left: 20px;
              margin-right: 20px;
            }
            p {
              margin: 0;
              margin-right: 7px;
              font-size: 22px;
            }
          }
          .productDetailsSeparator {
            width: 325px;
            height: 1px;
            background-color: #ededed;
          }

          .price {
            font-family: "FuturaMedium";
          }
        }
      }
      .productDescriptionContainer {
        margin-right: auto;
        margin-left: auto;
        width: 1200px;
        display: flex;
        flex-direction: column;
        align-items: center;
        .productDescriptionHeader {
          width: 1150px;
          display: flex;
          justify-content: left;
          align-items: center;
          svg {
            margin-right: 13px;
          }
          p {
            font-size: 20px;
            margin: 0;
            margin-right: 13px;
          }
        }
        .productDetailsSeparator {
          margin-top: 20px;
          width: 1150px;
          height: 1px;
          background-color: #ededed;
        }
      }
    }
  }
}
@media screen and (max-width: 1365px) and (min-width: 768px) {
  .product {
    a {
      text-decoration: none;
      .backLink {
        display: flex;
        flex-direction: row;
        justify-content: left;
        align-items: center;
        width: 629px;
        svg {
          color: #e30613;
          margin-bottom: 2px;
        }
        p {
          font-size: 20px;
          margin: 0 2.5px;
          font-family: "Kollektif";
        }
        .productName {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
    .productVideo {
      overflow: hidden;
      border-radius: 5px;
      width: 650px;
      height: 370px;
      box-shadow: 0 0 10px #295cd420;
    }
    .productDetails {
      width: 629px;
      height: 184px;
      margin: 13px;
      display: flex;
      flex-direction: column;
      align-items: center;
      div {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 60px;
        width: 629px;
        svg {
          margin-right: 13px;
        }
        p {
          margin: 0;
          margin-right: 5px;
          font-size: 20px;
        }
      }
      .productDetailsSeparator {
        width: 650px;
        height: 1px;
        background-color: #ededed;
      }
      .price {
        font-family: "FuturaMedium";
      }
    }
    .productDescriptionContainer {
      width: 629px;
      display: flex;
      flex-direction: column;
      align-items: center;
      div {
        width: 629px;
        display: flex;
        justify-content: left;
        align-items: center;
        svg {
          margin-right: 13px;
        }
        p {
          font-size: 20px;
          margin: 0;
        }
      }
      .productDetailsSeparator {
        margin-top: 20px;
        width: 650px;
        height: 1px;
        background-color: #ededed;
        margin: 20px 0;
      }
    }
    .purchaseButton {
      width: 650px;
      height: 45px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border: none;
      border-radius: 4px;
      background-color: #e30613;
      p {
        margin-left: 13px;
        font-family: "Kollektif";
        font-size: 20px;
        color: white;
      }
      svg {
        margin-right: 13px;
        color: white;
      }
    }
  }
}
