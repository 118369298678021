@media screen and (max-width: 1365px) {
  .notFound {
    height: 100vh;
    width: 100%;
    position: relative;
    p {
      position: absolute;
      width: 285px;
      height: 285px;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      margin: auto;
    }
    .e404 {
      font-size: 144px;
      line-height: 144px;
      height: 144px;
      font-family: "KollektifBold";
      color: #e30613;
      opacity: 0.1;
      pointer-events: none;
    }
    .oops {
      font-size: 35px;
      line-height: 35px;
      text-align: center;
      height: 70px;
    }
    button {
      position: absolute;
      width: 285px;
      height: 25px;
      font-size: 25px;
      font-family: "Kollektif";
      line-height: 25px;
      left: 0;
      right: 0;
      margin: auto;
      bottom: calc(50vh - 140px);
      border: none;
      background-color: transparent;
      color: #e30613;
    }
  }
}
@media screen and (min-width: 1366px) {
  .notFound {
    height: 100vh;
    width: 100%;
    position: relative;
    p {
      position: absolute;
      width: 285px;
      height: 285px;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      margin: auto;
    }
    .e404 {
      font-size: 144px;
      line-height: 144px;
      height: 144px;
      font-family: "KollektifBold";
      color: #e30613;
      opacity: 0.1;
      pointer-events: none;
    }
    .oops {
      font-size: 35px;
      line-height: 35px;
      text-align: center;
      height: 70px;
    }
    button {
      position: absolute;
      width: 285px;
      height: 25px;
      font-size: 25px;
      font-family: "Kollektif";
      line-height: 25px;
      left: 0;
      right: 0;
      margin: auto;
      bottom: calc(50vh - 140px);
      border: none;
      background-color: transparent;
      color: #e30613;
    }
  }
}
