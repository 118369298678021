@media screen and (min-width: 1366px) {
  .items {
    .itemCards {
      width: 1200px;
      display: grid;
      column-gap: 45px;
      row-gap: 45px;
      grid-template-columns: 1fr 1fr 1fr;
    }
    .showMoreItemsButtonContainer {
      width: 1200px;
      display: flex;
      justify-content: center;
      align-items: center;
      .showMoreItemsButton {
        width: 370px;
        height: 63px;
        border: none;
        border-radius: 5px;
        background-color: #262626;
        margin: 20px 0;
        box-shadow: 0 0 10px #295cd420;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        p {
          color: white;
          font-family: "Kollektif";
          font-size: 18px;
          margin-left: 13px;
        }
        svg {
          color: white;
          margin-right: 13px;
        }
      }
    }
  }
}

@media screen and (max-width: 1365px) {
  .items {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .showMoreItemsButton {
      width: 306px;
      height: 50px;
      border: none;
      border-radius: 5px;
      background-color: #262626;
      margin: 20px 0;
      box-shadow: 0 0 10px #295cd420;
      display: flex;
      align-items: center;
      justify-content: space-between;
      p {
        color: white;
        font-family: "Kollektif";
        font-size: 18px;
        margin-left: 13px;
      }
      svg {
        color: white;
        margin-right: 13px;
      }
    }
  }
}

@media screen and (max-width: 1365px) and (min-width: 768px) {
  .items {
    .itemCards {
      width: 650px;
      display: grid;
      column-gap: 40px;
      row-gap: 20px;
      grid-template-columns: 1fr 1fr;
    }
    .showMoreItemsButtonContainer {
      width: 650px;
      display: flex;
      justify-content: left;
      align-items: center;
      .showMoreItemsButton {
        width: 305px;
        height: 63px;
        border: none;
        border-radius: 5px;
        background-color: #262626;
        margin: 40px 0;
        box-shadow: 0 0 10px #295cd420;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        p {
          color: white;
          font-family: "Kollektif";
          font-size: 18px;
          margin-left: 13px;
        }
        svg {
          color: white;
          margin-right: 13px;
        }
      }
    }
  }
}
