@media screen and (max-width: 1365px) {
  .contact {
    .contactContainer {
      width: 306px;
      // height: 188px;
      background-color: #262626;
      border-radius: 5px;
      overflow: hidden;
      a {
        text-decoration: none;
      }
      .contactOption {
        height: calc((188px - 2px) / 3);
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #393939;
        div {
          display: flex;
          flex-direction: row;
          align-items: center;
          svg {
            margin: 0 13px;
            color: white;
          }
          p {
            color: white;
          }
        }
        svg {
          margin: 0 13px;
          color: white;
        }
      }
    }
  }
}
@media screen and (min-width: 1366px) {
  .contact {
    .contactContainer {
      width: 1200px;
      height: 63px;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: space-between;
      a {
        text-decoration: none;
      }
      .contactOption {
        width: 370px;
        height: 63px;
        background-color: #262626;
        border-radius: 5px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        div {
          display: flex;
          flex-direction: row;
          align-items: center;
          svg {
            margin: 0 20px;
            color: white;
          }
          p {
            color: white;
          }
        }
        svg {
          margin: 0 20px;
          color: white;
        }
      }
    }
  }
}

@media screen and (max-width: 1365px) and (min-width: 768px) {
  .contact {
    .contactContainer {
      width: 650px;
      // height: 188px;
      background-color: #262626;
      border-radius: 5px;
      overflow: hidden;
      a {
        text-decoration: none;
      }
      .contactOption {
        height: calc((188px - 2px) / 3);
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #393939;
        div {
          display: flex;
          flex-direction: row;
          align-items: center;
          svg {
            margin: 0 13px;
            color: white;
          }
          p {
            color: white;
          }
        }
        svg {
          margin: 0 13px;
          color: white;
        }
      }
    }
  }
}
