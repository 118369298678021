.productPicturesPopupBg {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  background-color: #26262620;
  backdrop-filter: blur(2px);
  .productPicturesPopup {
    z-index: 1001;
    position: fixed;
    width: 1200px;
    height: 600px;
    max-height: 95vh;
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: white;
    box-shadow: 0 0 10px #295cd420;
    border-radius: 5px;
    display: flex;
    .picSelector::-webkit-scrollbar {
      display: none;
    }
    .picSelector {
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
      width: 120px;
      height: 100%;
      overflow-y: scroll;
      overflow-x: hidden;
      .picSelectorThumb {
        margin: 15px;
        width: 90px;
        height: 90px;
        margin-bottom: 0;
        box-shadow: 0 0 10px #295cd420;
        border-radius: 5px;
        background-size: cover;
        background-position: center center;
      }
    }
    .picContainer {
      width: 1100px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: left;
      .pic {
        width: 1070px;
        height: 570px;
        border-radius: 5px;
        box-shadow: 0 0 10px #295cd420;
        background-size: cover;
        background-position: center center;
      }
    }
  }
}
