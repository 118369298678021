@media screen and (max-width: 1365px) {
  .itemCard {
    width: 306px;
    height: 398px;
    border-radius: 5px;
    overflow: hidden;
    margin-top: 20px;
    box-shadow: 0 0 10px #295cd420;
    .itemCardPicture {
      width: 100%;
      height: 212px;
      overflow: hidden;
      background-color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      border-bottom: 3px solid #e30613;
      img {
        width: 100%;
      }
    }
    .itemDescription {
      width: calc(100% - 26px);
      height: 100px;
      margin: 13px;
      display: flex;
      flex-direction: column;
      div {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 33px;
        svg {
          margin-right: 13px;
        }
        p {
          margin-right: 5px;
        }
        .elipsis {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .price {
          font-family: "FuturaMedium";
        }
      }
    }
    .itemButtons {
      display: flex;
      width: calc(100% - 26px);
      margin: 13px;
      justify-content: space-between;
      .infoButton {
        width: 70px;
        height: 45px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        border-radius: 4px;
        background-color: #262626;
        color: white;
      }
      .purchaseButton {
        width: 280px;
        height: 45px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: none;
        border-radius: 4px;
        background-color: #e30613;
        p {
          margin-left: 13px;
          font-family: "Kollektif";
          font-size: 20px;
          color: white;
        }
        svg {
          margin-right: 13px;
          color: white;
        }
      }
    }
  }
}
@media screen and (min-width: 1366px) {
  .itemCard {
    width: 370px;
    height: 398px;
    border-radius: 5px;
    overflow: hidden;
    margin-top: 20px;
    box-shadow: 0 0 10px #295cd420;
    .itemCardPicture {
      width: 100%;
      height: 212px;
      overflow: hidden;
      background-color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      border-bottom: 3px solid #e30613;
      img {
        width: 100%;
      }
    }
    .itemDescription {
      width: calc(100% - 26px);
      height: 100px;
      margin: 13px;
      display: flex;
      flex-direction: column;
      div {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 33px;
        svg {
          margin-right: 13px;
        }
        p {
          margin-right: 5px;
        }
        .elipsis {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .price {
          font-family: "FuturaMedium";
        }
      }
    }
    .itemButtons {
      display: flex;
      width: calc(100% - 26px);
      margin: 13px;
      justify-content: space-between;
      .infoButton {
        width: 70px;
        height: 45px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        border-radius: 4px;
        background-color: #262626;
        color: white;
      }
      .purchaseButton {
        width: 344px;
        height: 45px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: none;
        border-radius: 4px;
        background-color: #e30613;
        cursor: pointer;
        p {
          margin-left: 13px;
          font-family: "Kollektif";
          font-size: 20px;
          color: white;
        }
        svg {
          margin-right: 13px;
          color: white;
        }
      }
    }
  }
}
// @media screen and (max-width: 1365px) and (min-width: 768px) {
//   .itemCard {
//     margin: 0;
//   }
// }
// @media screen and (max-width: 1365px) and (min-width: 768px) {
//   .itemCard {
//     width: 305px;
//     height: 395px;
//     border-radius: 5px;
//     overflow: hidden;
//     margin-top: 20px;
//     box-shadow: 0 0 10px #295cd420;
//     .itemCardPicture {
//       width: 100%;
//       height: 212px;
//       overflow: hidden;
//       background-color: white;
//       display: flex;
//       justify-content: center;
//       align-items: center;
//       border-bottom: 3px solid #e30613;
//       img {
//         width: 100%;
//       }
//     }
//     .itemDescription {
//       width: calc(100% - 26px);
//       height: 100px;
//       margin: 13px;
//       display: flex;
//       flex-direction: column;
//       div {
//         display: flex;
//         flex-direction: row;
//         align-items: center;
//         height: 33px;
//         svg {
//           margin-right: 13px;
//         }
//         p {
//           margin-right: 5px;
//         }
//         .elipsis {
//           white-space: nowrap;
//           overflow: hidden;
//           text-overflow: ellipsis;
//         }

//         .price {
//           font-family: "FuturaMedium";
//         }
//       }
//     }
//     .itemButtons {
//       display: flex;
//       width: calc(100% - 26px);
//       margin: 13px;
//       justify-content: space-between;
//       .infoButton {
//         width: 70px;
//         height: 45px;
//         display: flex;
//         justify-content: center;
//         align-items: center;
//         border: none;
//         border-radius: 4px;
//         background-color: #262626;
//         color: white;
//       }
//       .purchaseButton {
//         width: 280px;
//         height: 45px;
//         display: flex;
//         justify-content: space-between;
//         align-items: center;
//         border: none;
//         border-radius: 4px;
//         background-color: #e30613;
//         p {
//           margin-left: 13px;
//           font-family: "Kollektif";
//           font-size: 20px;
//           color: white;
//         }
//         svg {
//           margin-right: 13px;
//           color: white;
//         }
//       }
//     }
//   }
// }
