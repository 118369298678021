.hamburgerModal {
  position: absolute;
  top: 140px;
  left: 0;
  width: 100%;
  height: 250px;
  background-color: white;
  border-bottom: 1px solid #efefef;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 999;
  a {
    text-decoration: none;
    width: 306px;
    color: #262626;
    font-size: 20px;
    padding: 10px 0;
    margin: 10px 0;
  }
}

@media screen and (max-width: 1365px) and (min-width: 768px) {
  .hamburgerModal {
    position: absolute;
    top: 200px;
    left: 0;
    width: 100%;
    height: 250px;
    background-color: white;
    border-bottom: 1px solid #efefef;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 999;
    a {
      text-decoration: none;
      width: 650px;
      color: #262626;
      font-size: 20px;
      padding: 10px 0;
      margin: 10px 0;
    }
  }
}
