@media screen and (max-width: 1365px) {
  .navbar {
    width: 306px;
    height: 140px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    z-index: 1000;
    .logo {
      width: 49px;
    }
    .hamburger {
      width: 36px;
    }
    .desktopNavigation {
      display: none;
    }
  }
}

@media screen and (min-width: 1366px) {
  .navbar {
    width: 1200px;
    height: 215px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .logo {
      width: 49px;
    }
    .hamburger {
      width: 36px;
      display: none;
    }
    .desktopNavigation {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 415px;
      a {
        text-decoration: none;
        color: #262626;
        font-size: 22px;
      }
    }
  }
}

@media screen and (max-width: 1365px) and (min-width: 768px) {
  .navbar {
    width: 650px;
    height: 200px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    z-index: 1000;
    .logo {
      width: 49px;
    }
    .hamburger {
      width: 36px;
    }
    .desktopNavigation {
      display: none;
    }
  }
}
