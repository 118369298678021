@media screen and (max-width: 1365px) {
  .modalBg {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    background-color: #26262620;
    backdrop-filter: blur(2px);
    .modal {
      position: absolute;
      width: 286px;
      height: 50px;
      padding: 10px;
      box-shadow: 0 0 10px #295cd420;
      border-radius: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      top: 115px;
      left: calc((100% - 306px) / 2);
      background-color: white;
      p {
        text-align: center;
        margin: 2.5px;
      }
    }
  }
}
