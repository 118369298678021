@media screen and (max-width: 1365px) {
  .hero {
    p {
      margin: 0;
      width: 285px;
    }
    .p1 {
      font-size: 70px;
      line-height: 70px;
    }
    .p2 {
      font-size: 70px;
      font-family: "KollektifBold";
      line-height: 45px;
    }
    .p3 {
      font-size: 20px;
      line-height: 20px;
      margin-top: 15px;
    }
    .p4 {
      font-size: 50px;
      line-height: 40px;
      font-family: "KollektifBold";
      color: #e30613;
    }
    .p5 {
      font-size: 20px;
      line-height: 23px;
      margin-top: 20px;
      width: 285px;
      p {
        display: inline;
      }
      .red {
        color: #e30613;
      }
      .bold {
        font-family: "KollektifBold";
      }
    }
  }
}
@media screen and (min-width: 1366px) {
  .hero {
    width: 1200px;
    p {
      margin: 0 10px;
      width: 285px;
    }
    .p1 {
      font-size: 70px;
      line-height: 70px;
    }
    .p2 {
      font-size: 70px;
      font-family: "KollektifBold";
      line-height: 45px;
    }
    .p3 {
      font-size: 20px;
      line-height: 20px;
      margin-top: 15px;
    }
    .p4 {
      font-size: 50px;
      line-height: 40px;
      font-family: "KollektifBold";
      color: #e30613;
    }
    .p5 {
      font-size: 20px;
      line-height: 22px;
      margin: 0 10.5px;
      width: calc(650px - 10.5px * 2);
      margin-top: 20px;
      p {
        display: inline;
        margin: 0;
      }
      .red {
        color: #e30613;
      }
      .bold {
        font-family: "KollektifBold";
      }
    }
  }
}

@media screen and (max-width: 1365px) and (min-width: 768px) {
  .hero {
    p {
      margin: 0 10.5px;
      width: calc(650px - 10.5px * 2);
    }
    .p1 {
      font-size: 70px;
      line-height: 70px;
    }
    .p2 {
      font-size: 70px;
      font-family: "KollektifBold";
      line-height: 45px;
    }
    .p3 {
      font-size: 20px;
      line-height: 20px;
      margin-top: 15px;
    }
    .p4 {
      font-size: 50px;
      line-height: 40px;
      font-family: "KollektifBold";
      color: #e30613;
    }
    .p5 {
      font-size: 20px;
      line-height: 22px;
      margin: 0 10.5px;
      width: calc(650px - 10.5px * 2);
      margin-top: 20px;
      p {
        display: inline;
        margin: 0;
      }
      .red {
        color: #e30613;
      }
      .bold {
        font-family: "KollektifBold";
      }
    }
  }
}
