@media screen and (min-width: 1366px) {
  .allProducts {
    .allProductsParagraph {
      width: 1200px;
    }
    .itemCards {
      display: grid;
      width: 1200px;
      column-gap: 45px;
      row-gap: 45px;
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
}

@media screen and (max-width: 1365px) and (min-width: 768px) {
  .allProducts {
    .allProductsParagraph {
      width: 650px;
    }
    .itemCards {
      display: grid;
      grid-template-columns: 1fr 1fr;
      width: 650px;
      column-gap: 40px;
      row-gap: 20px;
    }
  }
}
